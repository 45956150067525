export const Reducer = (state, action) => {
  switch (action.type) {
    case "GET_PROJECTS":
      return {
        ...state,
        allProjects: action.payload,
      };
    case "GET_ADAMRS":
      return {
        ...state,
        allAdamrs: action.payload,
      };
    case "GET_POSTS":
      return {
        ...state,
        allPosts: action.payload,
      };
    case "GET_ALL":
      return action.payload;
    default:
      return state;
  }
};
