import { useEffect, useRef } from "react";
import { canvasLoader } from "../assets/js/loader";
import "../assets/css/Loader.sass";
export const LoaderContainer = () => {
  const loader = canvasLoader;
  const loaderRef = useRef(null);
  //Loader
  useEffect(() => {
    const loaderEl = loaderRef.current;
    if (loaderEl !== null) loader(loaderEl);
  }, [loaderRef, loader]);
  
  //Presentation rendering
  return (
    <>
      <div className="center">
        <canvas id="canvas" width="350" height="350" ref={loaderRef}></canvas>
      </div>
      <p id="loader-p" title="Loading, please wait."></p>
    </>
  );
};
