import { PostEditor } from "../../../components/Space/Editor/Editor";
import "../../../../assets/css/Wyswig.sass";
import "../../../../assets/css/Editor.sass";
import { LoaderContainer } from "./Loader";
import { useState, useEffect } from "react";
import { fetchData } from "../../../../assets/js/functions";

export const EditorContainer = () => {
  const [content, setContent] = useState();
  const [load, setLoad] = useState(false);
  const [title, setTitle] = useState(false);
  useEffect(() => {
    if (load) {
      setLoad(false);
      const id = load.target.value;
      //Data fetching
      const processData = (data) => {
        setContent(data._id);
        setTitle(data.title);
      };
      const url = "db/posts/" + id;
      fetchData(url, processData);
    }
  }, [load]);

  //Container rendering
  return (
    <>
      <LoaderContainer loadPost={setLoad} state={content} title={title} setTitle={setTitle} />
      <PostEditor state={content} reloadPost={setLoad} title={title} />
    </>
  );
};
