import { config } from "../../../../assets/js/config";

export const AdamrButton = ({ story, setRequest, active, animationRef }) => {
  if (config.debug) console.log("Element Button re-rendered :( ");

  //Presentation rendering
  return (
    <div
      key={story.id}
      className={story.id === active ? "grid-menu-item active" : "grid-menu-item"}
      onClick={setRequest}
      data-content={story.label}
      data-id={story.id}
      ref={animationRef}
    >
      {story.label}
    </div>
  );
};
