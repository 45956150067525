import { config } from "../../../../assets/js/config";
import { ImageToolTip } from "./Image/ImageWithToolTip";
import { isSafari } from "../../../../assets/js/functions";
import { Controls } from "../Controls/Controls";

export const Project = ({ project, shiftProject }) => {
  if (config.debug) console.log("Element Projects re-rendered :( ");
  //Presentation rendering
  return (
    <div id="project-container">
      <div id="title">
        <h3 className={isSafari ? "safari" : "others"}>
          {project.title} ({project.id + 1}/{project.total})
        </h3>
      </div>
      <div id="description">{project.description}</div>
      <div id="technologies">
      <Controls id={project.id} shiftProject={shiftProject} dir={0} />
        <div>Tech stack:</div>
        {project.technologies.map((tech, idx) => (
          <div key={`img-${idx}`}>
            <ImageToolTip tech={tech} />
          </div>
        ))}
        {project.git && (
          <div
            id="git-source"
            onClick={() => {
              window.open(project.git);
            }}
          >
            <div>Source: </div>
            <ImageToolTip tech={"SourceLogo"} />
          </div>
        )}
        <Controls id={project.id} shiftProject={shiftProject} dir={1} />
      </div>
      {project.url && (
        <div
          className={isSafari ? "safari" : "others"}
          id="launcher"
          onClick={() => {
            window.open(project.url);
          }}
        >
          <p className={isSafari ? "safari" : "others"}>{project.launcher}</p>
        </div>
      )}
    </div>
  );
};
