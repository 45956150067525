import { config } from "../../../../assets/js/config";
import { isSafari } from "../../../../assets/js/functions";

export const Controls = ({ shiftProject, id, dir }) => {
  if(config.debug)console.log("Element Controls re-rendered :( ");
  //Presentation rendering
  return (
    <div className="controls">
      <div className={[dir===1?"right":"left", isSafari ? "safari" : "others"].join(" ")} onClick={shiftProject} data-id={id} data-direction={dir}>
        ▼
      </div>
    </div>
  );
};
