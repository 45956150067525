import { PostEditor2 } from "../../../components/Space/Editor2/Editor";
import "../../../../assets/css/Wyswig.sass";
import "../../../../assets/css/Editor.sass";
import { LoaderContainer2 } from "./Loader";
import { useState, useEffect } from "react";
import { fetchData } from "../../../../assets/js/functions";

export const EditorContainer2 = () => {
  const [content, setContent] = useState();
  const [load, setLoad] = useState(false);
  const [label, setLabel] = useState(false);
  useEffect(() => {
    if (load) {
      setLoad(false);
      const id = load.target.value;
      //Data fetching
      const processData = (data) => {
        setContent(data._id);
        setLabel(data.label);
      };

      const url = "db/adamr/" + id;
      fetchData(url, processData);
    }
  }, [load]);

  //Container rendering
  return (
    <>
      <LoaderContainer2 loadPost={setLoad} label={label} state={content} setLabel={setLabel} />
      <PostEditor2 state={content} reloadPost={setLoad} label={label} />
    </>
  );
};
