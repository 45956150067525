export const animations = {
  "space-projects": [
    [
      {
        opacity: 0,
        transform: "scale(0)",
      },
      {
        opacity: 1,
        transform: "scale(1)",
      },
    ],
    {
      duration: 500,
    },
  ],
  "space-home": [
    [
      {
        opacity: 0,
        transform: "scaleY(0)",
      },
      {
        opacity: 1,
        transform: "scaleY(1)",
      },
    ],
    {
      duration: 750,
    },
  ],
  "react-banner": [
    [
      {
        opacity: 0,
        color: "#75e9ec00",
      },
      {
        opacity: 1,
        color: "#75e9ec",
      },
    ],
    {
      duration: 750,
      delay: 750,
      fill: "forwards",
    },
  ],
  "space-adamr": [
    [
      {
        opacity: 0,
        transform: "scaleY(0)",
        transformOrigin: "bottom",
      },
      {
        opacity: 1,
        transform: "scaleY(1)",
      },
    ],
    {
      duration: 700,
    },
  ],
  "adamr-backplate": [
    [
      {
        opacity: 0,
      },
      {
        opacity: 1,
      },
    ],
    {
      duration: 500,
      delay: 700,
      fill: "forwards",
    },
  ],
  "adamr-grid-story": [
    [
      {
        opacity: 0,
        boxShadow: "0px 0px 0px 0px #000000d1",
        transition: "box-shadow",
      },
      {
        opacity: 1,
        boxShadow: "7px 11px 13px 2px #000000d1",
      },
    ],
    {
      duration: 500,
      delay: 1000,
      fill: "forwards",
    },
  ],
  "grid-menu-item": [
    [
      {
        opacity: 0,
        transform: "scaleX(0)",
        transformOrigin: "left",
      },
      {
        opacity: 1,
        transform: "scaleX(1)",
      },
    ],
    {
      duration: 400,
      delay: 1300,
      fill: "forwards",
    },
  ],
  "form-email": [
    [
      {
        opacity: 0,
        transform: "scaleX(0)",
        transformOrigin: "left",
      },
      {
        opacity: 1,
        transform: "scaleX(1)",
      },
    ],
    {
      duration: 400,
      delay: 0,
      fill: "forwards",
    },
  ],
};
