import "../../../assets/css/Home.sass";
import { useCallback, useEffect, useContext } from "react";
import { Home } from "../../../components/Space/Home/Home";
import { animations } from "../../../assets/js/animations";
import { Context } from "../../../store";
export const HomeContainer = () => {
  //Animations
  const animationRef = useCallback((node) => {
    if (node !== null) node.animate(...animations[node.id]);
  }, []);

  //DATA CACHING & PRE-LOADING

  const [state, dispatch] = useContext(Context);

  const onFirstRender = () => {
    if (state.allPosts.length === 0 || state.allAdamrs.length === 0 || state.allProjects.length === 0) {
      dispatch("GET_ALL");
    }
  };

  useEffect(onFirstRender, [dispatch, state]);

  //Container rendering
  return <Home homeRef={animationRef} reactRef={animationRef} />;
};
