import { Post } from "../../../../components/Space/Blog/Post/Post";
export const PostsContainer = ({ posts }) => {
  //Container rendering
  return (
    <div id="blog-posts-grid">
      {posts.map((post) => (
        <Post key={post.id} post={post} />
      ))}
    </div>
  );
};
