import "../assets/css/Navbar.sass";
import { NavLink } from "react-router-dom";
import { ReactComponent as AdamRLogo } from "../assets/img/default-monochrome-d32e6b.svg";
import { config } from "../assets/js/config";

export const Navbar = () => {
  if (config.debug) console.log("Element Navbar re-rendered :( ");
  //Presentation rendering
  return (
    <nav id="space-nav">
      <ul className="nav clearfix">
        <li className="nav-logo">
          <AdamRLogo />
        </li>
        <li className="nav-item">
          <NavLink to="/blog" activeClassName="active" id="btn-blog">
            blog
          </NavLink>
        </li>
        <li className="nav-separator">|</li>
        <li className="nav-item">
          <NavLink to="/adamr" activeClassName="active" id="btn-adamr">
            adamr
          </NavLink>
        </li>
        <li className="nav-separator">|</li>
        <li className="nav-item">
          <NavLink to="/projects" activeClassName="active" id="btn-projects">
            projects
          </NavLink>
        </li>
        <li className="nav-separator">|</li>
        <li className="nav-item">
          <NavLink to="/" exact={true} activeClassName="active" id="btn-space">
            home
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};
