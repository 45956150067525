import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { useState, useEffect } from "react";
import { fetchData, addIndexes } from "../../../../assets/js/functions";
import { config } from "../../../../assets/js/config";

export const PostEditor = ({ state, reloadPost, title }) => {
  const [componentState, setComponent] = useState({
    serverState: { content: null, title: null, id: null, save: false, new: false },
    editorContent: { original: null, draft: null, id: null },
    editorState: EditorState.createEmpty(),
    posts: {
      posts: [],
      currentIndex: 0,
      currentId: null,
      new: false,
      getPostIndexById(id) {
        if (config.debug) console.log(this);
        return this.posts.map((post) => post._id).indexOf(id);
      },
      getPostIdByIndex(index) {
        return this.posts[index]._id;
      },
      getContentByIndex(index) {
        return this.posts[index].content;
      },
      getContentById(id) {
        if (config.debug) console.log(this.posts, id);
        return this.posts[this.getPostIndexById(id)].content;
      },
      setCurrentById(id) {
        const index = this.getPostIndexById(id);
        setComponent((prevState) => {
          return { ...prevState, posts: { ...this.posts, currentIndex: index, currentId: id } };
        });
      },
      setCurrentByIndex(index) {
        const id = this.getPostIdByIndex(index);
        setComponent((prevState) => ({ ...prevState, posts: { ...this.posts, currentIndex: index, currentId: id } }));
      },
    },
  });

  //Data fetching
  useEffect(() => {
    if (!state) {
      const processData = (data) => {
        addIndexes(data);
        setComponent((prevState) => ({
          ...prevState,
          posts: { ...prevState.posts, posts: data, currentId: data[0]._id, currentIndex: data[0].id, new: false },
        }));
      };

      const url = "db/posts";
      fetchData(url, processData);
    } else {
      setComponent((prevState) => {
        return { ...prevState, posts: { ...prevState.posts, currentId: state } };
      });
    }
  }, [state]);

  //Process and Display Data
  useEffect(() => {
    if (componentState.posts.posts.length > 0) {
      const rawContent = componentState.posts.getContentById(componentState.posts.currentId);
      if (rawContent) {
        if (config.debug) console.log(rawContent);
        if (Object.keys(rawContent).length !== 0) {
          let converted = convertFromRaw(rawContent);
          converted = EditorState.createWithContent(converted);
          setComponent((prevState) => ({ ...prevState, editorState: converted }));
        } else setComponent((prevState) => ({ ...prevState, editorState: EditorState.createEmpty() }));
      }
    }
  }, [componentState.posts]);

  //Save Data
  useEffect(() => {
    if (componentState.serverState.save) {
      const savePost = async (state) => {
        try {
          let url = "db/posts";
          if (config.debug) url = "http://localhost:9876/" + url;
          const response = await fetch(`${url}/${state.id}`, {
            method: "PATCH",
            body: JSON.stringify({ content: state.content, title: title, author: "adamR" }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });
          const data = await response.json();
          window.location.reload(false);
        } catch (e) {
          if (config.debug) console.log(e);
        }
      };

      savePost(componentState.serverState);
    }
    if (componentState.serverState.new) {
      const newPost = async (state) => {
        try {
          let url = "db/posts";
          if (config.debug) url = "http://localhost:9876/" + url;
          const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify({ content: state.content, title: "new Post", author: "adamR" }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });
          const data = await response.json();
          if (config.debug) console.log(data);
          window.location.reload(false);
        } catch (e) {
          if (config.debug) console.log(e);
        }
      };

      newPost(componentState.serverState);
    }
  }, [componentState.serverState, reloadPost, title]);

  //Capture Editor Changes
  const handleEditorChange = (state) => {
    setComponent((prevState) => ({ ...prevState, editorState: state }));
    const contentState = componentState.editorState.getCurrentContent();
    setComponent((prevState) => ({
      ...prevState,
      editorContent: { ...prevState.editorContent, draft: convertToRaw(contentState), id: prevState.posts.currentId },
    }));
  };

  return (
    <>
      {componentState.editorState ? (
        <Editor
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          // wrapperStyle={<wrapperStyleObject>}
          // editorStyle={<editorStyleObject>}
          // toolbarStyle={<toolbarStyleObject>}
          onEditorStateChange={handleEditorChange}
          editorState={componentState.editorState}
        />
      ) : (
        ""
      )}
      <button
        onClick={() => {
          setComponent((prevState) => ({
            ...prevState,
            serverState: {
              ...prevState.serverState,
              content: prevState.editorContent.draft,
              id: prevState.editorContent.id,
              title: prevState.posts.posts[prevState.posts.getPostIndexById(prevState.posts.currentId)].title,
              save: true,
            },
          }));
        }}
      >
        Save
      </button>
      <button
        onClick={() => {
          setComponent((prevState) => ({
            ...prevState,
            editorState: EditorState.createEmpty(),
            editorContent: { original: null, draft: null, id: null },
            serverState: {
              ...prevState.serverState,
              content: prevState.editorContent.draft,
              id: prevState.editorContent.id,
              title: "new",
              save: false,
              new: true,
            },
            posts: { ...prevState.posts, posts: [], currentIndex: 0, currentId: "", new: false },
          }));
        }}
      >
        New
      </button>
    </>
  );
};
