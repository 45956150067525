import { AdamrButtonContainer } from "./Button";
export const AdamrMenuContainer = ({ stories, setRequest, active }) => {
  // Container rendering
  return (
    <div id="adamr-grid-menu">
      {stories.map((story) => (
        <AdamrButtonContainer key={story.id} story={story} setRequest={setRequest} active={active} />
      ))}
    </div>
  );
};
