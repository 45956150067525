// import { PostEditor } from "../../../components/Space/Editor/Editor";
import "../../../../assets/css/Wyswig.sass";
import "../../../../assets/css/Editor.sass";
import { useState, useEffect } from "react";
import { fetchData, addIndexes, formatDates } from "../../../../assets/js/functions";

export const LoaderContainer2 = ({ loadPost, label, setLabel }) => {
  const [posts, setPosts] = useState([]);
  const [options, setOptions] = useState();

  //Fill the select
  useEffect(() => {
    if (posts.length > 0) {
      setOptions(posts.map((post) => [post.label, post._id]));
      if (!label) setLabel(posts[0].label);
    }
  }, [posts, label, setLabel]);

  //Data fetching
  useEffect(() => {
    const processData = (data) => {
      addIndexes(data);
      formatDates(data);
      setPosts(data);
    };

    const url = "db/adamr";
    fetchData(url, processData);
  }, []);

  //Container rendering
  return (
    <div>
      <div>
        <select
          id="editor-select"
          onChange={(e) => {
            loadPost(e);
            setLabel(e.target.selectedOptions[0].dataset.label);
          }}
        >
          {options
            ? options.map((opt, idx) => (
                <option key={idx} value={opt[1]} data-id={opt[1]} data-label={opt[0]}>
                  {opt[0]}
                </option>
              ))
            : ""}
        </select>
      </div>
      <div id="editor-input">
        <label htmlFor="post-label">Title:</label>
        <input id="post-label" type="text" name="label" value={label} onChange={(e) => setLabel(e.target.value)} />
      </div>
    </div>
  );
};
