import { useCallback } from "react";
import { animations } from "../../../../assets/js/animations";
import { AdamrButton } from "../../../../components/Space/Adamr/Menu/Button";
export const AdamrButtonContainer = ({ story, setRequest, active }) => {
  //Animations
  const animationRef = useCallback((node) => {
    if (node !== null) {
      const id = node.classList[0];
      animations[id][1].delay += node.dataset.id * 30;
      node.animate(...animations[id]);
    }
  }, []);

  //Container rendering
  return <AdamrButton animationRef={animationRef} story={story} setRequest={setRequest} active={active} />;
};
