export const SidebarContainer = ({ posts }) => {
  //Container rendering
  return (
    <div id="blog-sidebars-grid">
      <div id="blog-sidebar-stats">
        <div className="blog-stats-title">Posts stats</div>
        <div className="blog-stats-content">
          <p>Posts count: {posts.length}</p>
          <p>Total words: {posts.words}</p>
        </div>
      </div>
    </div>
  );
};
