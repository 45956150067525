import { Images } from '../../../../assets/img/Images';
import { config } from '../../../../assets/js/config';
import ReactHtmlParser from 'react-html-parser';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { Pdfs } from '../../../../assets/pdf/pdfs';
export const Story = ({ story, animationRef, setModal }) => {
	if (config.debug) console.log('Element Story re-rendered :( ');

	let pdfs = story.pdfs
		? Object.values(Pdfs).map((pdf, i, arr) => (
				<div key={`pdf-${i}`}>
					<Viewer fileUrl={pdf.pdf} />
					{i < arr.length - 1 ? <h3 className={'pdf-tip'}>Scroll down for more</h3> : ''}
				</div>
			))
		: false;
	let images = story.images
		? story.images.map((img, idx) => (
				<p
					className="a"
					key={`${idx}-p`}
					onClick={() => {
						if (img.src === 'EmailLogo') setModal((prevState) => ({ ...prevState, modalOpened: true }));
						else window.open(Images[img.src].url);
					}}
				>
					<span>{img.text} </span>
					<img alt="" id={img.id} src={Images[img.src].img} />
				</p>
			))
		: false;

	//Presentation rendering
	return (
		<div id="adamr-grid-story" className={story.class} ref={animationRef}>
			{!images && !pdfs ? (
				<div className="a">{ReactHtmlParser(story.content)}</div>
			) : !pdfs ? (
				images
			) : (
				<div className="certs-list">
					<p>List of certificates from CodeAcademy:</p>
					<ul>
          <li>Learn Python 3</li>
            
						<li>Learn HTML</li>
            <li>Learn CSS</li>
            <li>Learn PHP</li>
            <li>Learn JavaScript</li>
						<li>Learn TypeScript</li>
						<li>Learn React</li>
            <li>Learn Node.js</li>
            <li>Learn Express</li>
            <li>Learn Git</li>
            <li>Learn SQL</li>
            <li>Learn Node-SQLite</li>
            <li>Learn jQuery</li>
            <li>Learn Bootstrap</li>
            <li>Learn Intermediate CSS</li>
						<li>Learn Color Design</li>
						<li>Learn Navigation Design</li>
						<li>How to Debug JavaScript Errors</li>
						<li>Learn Asynchronous JavaScript</li>
						<li>Learn Responsive Design</li>
						<li>Learn the Command Line</li>
						<li>Building Interactive JavaScript Websites</li>
						<li>Learn JavaScript Unit Testing</li>
            <li>Learn the Basics of Regular Expressions</li>
					</ul>
          <p>Skill Path Certificates from CodeAcademy:</p>
					<ul>
						<li>Build a Website with HTML, CSS, and Github Pages</li>
						<li>Create a Back-End App with JavaScript</li>
						<li>Create a Front-End App with React</li>
						<li>Create an Advanced Web App with React and Redux</li>
					</ul>

          <p>CISCO Certificates:</p>
					<ul>
						<li>Introduction to Packet Tracer</li>
						<li>Network Essentials</li>
					</ul>
        <h3>Please scroll down to see each certificate</h3>
					<Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">{pdfs}</Worker>
				</div>
			)}
		</div>
	);
};
