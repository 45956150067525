export const canvasLoader = (el) => {
  const cvs = el;
  const ctx = cvs.getContext("2d");
  let sA = (Math.PI / 180) * 45;
  let sE = (Math.PI / 180) * 90;
  const ca = cvs.width;
  const ch = cvs.height;
  setInterval(() => {
    ctx.clearRect(0, 0, ca, ch);
    ctx.lineWidth = 35;

    ctx.beginPath();
    ctx.strokeStyle = "#ffffff";
    ctx.shadowColor = "#eeeeee";
    ctx.shadowOffsetX = 2;
    ctx.shadowOffsetY = 2;
    ctx.shadowBlur = 5;
    ctx.arc(175, 175, 100, 0, 360, false);
    ctx.stroke();
    ctx.closePath();

    sE += 0.05;
    sA += 0.05;

    ctx.beginPath();
    ctx.strokeStyle = "#aaaaaa";
    ctx.arc(175, 175, 100, sA, sE, false);
    ctx.stroke();
    ctx.closePath();
  }, 6);
};
