import React from "react";
import ReactDOM from "react-dom";
import 'normalize.css'
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Store from "./store";
import { ClearCacheProvider } from 'react-clear-cache';

ReactDOM.render(
  <React.StrictMode>
    <ClearCacheProvider duration={5000} filename="build.json">
    <Store>
      <App />
    </Store>
    </ClearCacheProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(if(config.debug)console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// reportWebVitals(console.log);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();