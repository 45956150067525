import { config } from "./config";
import draftToHtml from "draftjs-to-html";

export const fetchData = async (url, dataProcessor) => {
  try {
    if (config.debug) url = "http://192.168.2.222:9876/" + url;
    const response = await fetch(url);
    const data = await response.json();
    if (dataProcessor) dataProcessor(data);
  } catch (e) {
    if (config.debug) console.log(e);
    // setData(null);
  }
};
export const fetchDataRet = async (url, dataProcessor) => {
  try {
    if (config.debug) url = "http://192.168.2.222:9876/" + url;
    const response = await fetch(url);
    const data = await response.json();
    if (dataProcessor) dataProcessor(data);
    return data;
  } catch (e) {
    if (config.debug) console.log(e);
    // setData(null);
  }
};

export const addIndexes = (data) => {
  data = data.map((entry, idx) => {
    entry.id = idx;
    entry.total = data.length;
    return entry;
  });
};

export const formatDates = (data) => {
  data = data.map((entry) => {
    entry.dateCreated = new Date(entry.dateCreated);
    return entry;
  });
};

export const convertPosts = (data) => {
  data = data.map((post) => {
    post.content = draftToHtml(post.content);
    return post;
  });
};

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(!window["safari"] || (typeof safari !== "undefined" && window["safari"].pushNotification));
