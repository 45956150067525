import { createContext, useReducer } from "react";
import { Reducer } from "./reducer/reducer";
import { fetchDataRet, addIndexes, formatDates, convertPosts } from "./assets/js/functions";

const initialState = {
  allPosts: [],
  allAdamrs: [],
  allProjects: [],
};

const projectsProcessor = (data) => {
  data = data.filter((entry) => entry.enabled);
  addIndexes(data);
};
const adamrProcessor = (data) => {
  addIndexes(data);
  convertPosts(data);
};
const postsProcessor = (data) => {
  addIndexes(data);
  formatDates(data);
  convertPosts(data);
  data.words = data.reduce((acc, post) => acc + post.content.split(" ").length, 0);
};

const fetchParams = {
  GET_PROJECTS: { url: "db/projects", dataProcessor: projectsProcessor, name: "allProjects" },
  GET_ADAMRS: { url: "db/adamr", dataProcessor: adamrProcessor, name: "allAdamrs" },
  GET_POSTS: { url: "db/posts", dataProcessor: postsProcessor, name: "allPosts" },
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  const asyncDispatch = async (action) => {
    let data = null;
    if (action === "GET_ALL") {
      data = {};
      for (const key in fetchParams) {
        data[fetchParams[key].name] = await fetchDataRet(fetchParams[key].url, fetchParams[key].dataProcessor);
      }
    } else data = await fetchDataRet(fetchParams[action].url, fetchParams[action].dataProcessor);
    dispatch({ type: action, payload: data });
  };
  return <Context.Provider value={[state, asyncDispatch]}>{children}</Context.Provider>;
};

export const Context = createContext(initialState);

export default Store;
