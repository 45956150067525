import "../assets/css/Space.sass";
import { Route } from "react-router-dom";
import { AdamrContainer } from "../containers/Space/Adamr/Adamr";
import { HomeContainer } from "./Space/Home/Home";
import { ProjectsContainer } from "./Space/Projects/Projects";
import { BlogContainer } from "./Space/Blog/Blog";
import { config } from "../assets/js/config";

export const Space = () => {
  if (config.debug) console.log("Element Space re-rendered :( ");
  //Presentation rendering
  return (
    <div id="space">
      <Route path="/blog" exact={true} component={BlogContainer} />
      <Route path="/projects" exact={true} component={ProjectsContainer} />
      <Route path="/adamr" exact={true} component={AdamrContainer} />
      <Route path="/" exact={true} component={HomeContainer} />
    </div>
  );
};
