import "../../../../assets/css/Modal.sass";
import { AdamrModal } from "../../../../components/Space/Adamr/Story/Modal";
import { animations } from "../../../../assets/js/animations";
import { useCallback } from "react";

export const AdamrModalContainer = ({ setModal, modal }) => {
  //Animations
  const animationRef = useCallback((node) => {
    if (node !== null) node.animate(...animations[node.id]);
  }, []);

  //Container rendering
  return (
    <div className={"modal"}>
      <AdamrModal setModal={setModal} modal={modal} animationRef={animationRef} />
    </div>
  );
};
