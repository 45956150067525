import "./assets/css/App.sass";
import { Navbar } from "./containers/Navbar";
import { config } from "./assets/js/config";
import { Footer } from "./containers/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import { useClearCacheCtx } from "react-clear-cache";
import { ReactComponent as AdamRLogo } from "./assets/img/default-monochrome-d32e6b.svg";
let Space = null;
if (config.debug) Space = require("./debug/containers/Space_debug").Space;
else Space = require("./containers/Space").Space;

function App() {
  const currentPath = document.URL;
  const { isLatestVersion, emptyCacheStorage, latestVersion } = useClearCacheCtx();
  //Presentation rendering
  return (
    <>
      {!isLatestVersion ? (
        <div className="update-container">
          <div>
            <p>
              <strong>Is latest version</strong>: {isLatestVersion ? "Yes" : "No"}
            </p>
            <p>
              <strong>Latest version</strong>: {latestVersion}
            </p>
          </div>
          <AdamRLogo />
          <button
            onClick={(e) => {
              e.preventDefault();
              emptyCacheStorage();
            }}
          >
            Update version
          </button>
        </div>
      ) : (
        <Router>
          <div className="App">
            <Navbar />
            <Space />
            {!currentPath.includes("/editor") && <Footer />}
          </div>
        </Router>
      )}
    </>
  );
}

export default App;
