import "../../assets/css/Space.sass";
import { Route } from "react-router-dom";
import { AdamrContainer } from "../../containers/Space/Adamr/Adamr";
import { HomeContainer } from "../../containers/Space/Home/Home";
import { ProjectsContainer } from "../../containers/Space/Projects/Projects";
import { BlogContainer } from "../../containers/Space/Blog/Blog";
import { EditorContainer } from "./Space/Editor/Editor";
import { EditorContainer2 } from "./Space/Editor2/Editor";
import { config } from "../../assets/js/config";

export const Space = () => {
  if (config.debug) console.log("Element Space re-rendered :( ");
  //Presentation rendering
  return (
    <div id="space">
      <Route path="/blog" component={BlogContainer} />
      <Route path="/projects" component={ProjectsContainer} />
      <Route path="/adamr" component={AdamrContainer} />
      {config.debug && <Route path="/editor" component={EditorContainer} />}
      {config.debug && <Route path="/editor2" component={EditorContainer2} />}
      <Route path="/" exact component={HomeContainer} />
    </div>
  );
};
