import ReactHtmlParser from "react-html-parser";
import { config } from "../../../../assets/js/config";

export const Post = ({ post }) => {
  if (config.debug) console.log("Element Post re-rendered :( ");

  //Presentation rendering
  return (
    <div id="post-a" className="blog-post">
      <div className="blog-post-title c">{post.title}</div>
      <div className="blog-post-info">
        <div className="blog-post-author">Author: {post.author}</div>
        <div className="blog-post-date">
          Date Created:{" "}
          {new Date(post.createdAt).toLocaleString("en-GB", {
            timeZone: "Europe/London",
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
          })}
        </div>
        <div className="blog-post-words">Word Count: {post.content.split(" ").length}</div>
      </div>
      <div className="blog-post-content a">{ReactHtmlParser(post.content)}</div>
    </div>
  );
};
