import "../../../assets/css/Blog.sass";
import { SidebarContainer } from "./Sidebar/Sidebar";
import { PostsContainer } from "./Posts/Posts";
import { LoaderContainer } from "../../Loader";
import { useEffect, useContext } from "react";
import { Context } from "../../../store";

export const BlogContainer = () => {
  //DATA CACHING & PRE-LOADING

  const [state, dispatch] = useContext(Context);
  const allPosts = state.allPosts;

  const onFirstRender = () => {
    if (allPosts.length === 0) {
      dispatch("GET_POSTS");
    }
  };

  useEffect(onFirstRender, [dispatch, allPosts]);

  //Container rendering
  return (
    <div id="space-blog">
      {allPosts ? (
        <div id="blog-container-grid">
          <SidebarContainer posts={allPosts} />
          <PostsContainer posts={allPosts} />
        </div>
      ) : (
        <LoaderContainer />
      )}
    </div>
  );
};
