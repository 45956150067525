import HtmlCert from "./HTML_-_adamr.space___Codecademy.pdf";
import CssCert from "./CSS_-_adamr.space___Codecademy.pdf";
import BootstrapCert from "./Bootstrap_-_adamr.space___Codecademy.pdf";
import JavascriptCert from "./JavaScript_-_adamr.space___Codecademy.pdf";
import JqueryCert from "./jQuery_-_adamr.space___Codecademy.pdf";
import NodeCert from "./Node.js_-_adamr.space___Codecademy.pdf";
import ExpressCert from "./Express_-_adamr.space___Codecademy.pdf";
import GitCert from "./Git_-_adamr.space___Codecademy.pdf";
import UnitTestingCert from "./JavaScript_Unit_Testing_-_adamr.space___Codecademy.pdf";
import ReactCert from "./React_-_adamr.space___Codecademy.pdf";
import BuildingWebsitesCert from "./Building_Interactive_JavaScript_Websites_-_adamr.space___Codecademy.pdf";
import TypescriptCert from "./Typescript_-_adamr.space___Codecademy.pdf";
import BackendCert from "./Back_end_-_adamr.space___Codecademy.pdf";
import WithGitCert from "./Build_websites_with_github_-_adamr.space___Codecademy.pdf";
import Python3Cert from "./python3_-_adamr.space _ Codecademy.pdf";
import IntermediateCssCert from "./Intermediate_CSS_-_adamr.space___Codecademy.pdf";
import ColourDesignCert from "./Colour_Design_-_adamr.space___Codecademy.pdf";
import NavigationDesignCert from "./Navigation_Design_-_adamr.space___Codecademy.pdf";
import SQL3Cert from "./Learn_SQL_-_adamr.space___Codecademy.pdf";
import JSDebugging3Cert from "./Debug_JS_Errors_-_adamr.space___Codecademy.pdf";
import NodeSQLiteCert from "./Node_SQLite_-_adamr.space___Codecademy.pdf";
import AsyncJS3Cert from "./Learn_async_JS_-_adamr.space___Codecademy.pdf";
import ResponsiveDesignCert from "./Learn_responsive_design_-_adamr.space___Codecademy.pdf";
import AppReactDesignCert from "./Front-endAppReact_-_adamr.space___Codecademy.pdf";
import AdvReactDesignCert from "./AdvancedReact_-_adamr.space___Codecademy.pdf";
import PHPCert from "./PHP_-_adamr.space___Codecademy.pdf";
import RegExCert from "./RegEx_-_adamr.space___Codecademy.pdf";
import PacketTracerCert from "./AdamRzymski-TM129_21B_Introd-certificate.pdf";
import NetworkEssentialsCert from "./AdamRzymski-TM129_21B_Networ-certificate.pdf";

export const Pdfs = {
  HtmlCert: { pdf: HtmlCert, toolTip: "", url: "" },
  CssCert: { pdf: CssCert, toolTip: "", url: "" },
  BootstrapCert: { pdf: BootstrapCert, toolTip: "", url: "" },
  JavascriptCert: { pdf: JavascriptCert, toolTip: "", url: "" },
  JqueryCert: { pdf: JqueryCert, toolTip: "", url: "" },
  NodeCert: { pdf: NodeCert, toolTip: "", url: "" },
  ExpressCert: { pdf: ExpressCert, toolTip: "", url: "" },
  GitCert: { pdf: GitCert, toolTip: "", url: "" },
  UnitTestingCert: { pdf: UnitTestingCert, toolTip: "", url: "" },
  ReactCert: { pdf: ReactCert, toolTip: "", url: "" },
  BuildingWebsitesCert: { pdf: BuildingWebsitesCert, toolTip: "", url: "" },
  TypescriptCert: { pdf: TypescriptCert, toolTip: "", url: "" },
  BackendCert: { pdf: BackendCert, toolTip: "", url: "" },
  WithGitCert: { pdf: WithGitCert, toolTip: "", url: "" },
  Python3Cert: { pdf: Python3Cert, toolTip: "", url: "" },
  IntermediateCssCert: { pdf: IntermediateCssCert, toolTip: "" },
  ColourDesignCert: { pdf: ColourDesignCert, toolTip: "" },
  NavigationDesignCert: { pdf: NavigationDesignCert, toolTip: "" },
  SQL3Cert: { pdf: SQL3Cert, toolTip: "" },
  JSDebugging3Cert: { pdf: JSDebugging3Cert, toolTip: "" },
  NodeSQLiteCert: { pdf: NodeSQLiteCert, toolTip: "" },
  AsyncJS3Cert: { pdf: AsyncJS3Cert, toolTip: "" },
  ResponsiveDesignCert: { pdf: ResponsiveDesignCert, toolTip: "" },
  AppReactDesignCert: { pdf: AppReactDesignCert, toolTip: "" },
  AdvReactDesignCert: { pdf: AdvReactDesignCert, toolTip: "" },
  PHPCert: { pdf: PHPCert, toolTip: "" },
  RegExCert: { pdf: RegExCert, toolTip: "" },
  PacketTracerCert: { pdf: PacketTracerCert, toolTip: "", url: "" },
  NetworkEssentialsCert: { pdf: NetworkEssentialsCert, toolTip: "", url: "" },
};
