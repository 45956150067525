import { Images } from "../../../../../assets/img/Images";
import { config } from "../../../../../assets/js/config";

export const ImageToolTip = ({ tech }) => {
  if (config.debug) console.log("Element Projects re-rendered :( ");
  //Presentation rendering
  return (
    <div data-tip={Images[tech].toolTip} className="tip">
      <img src={Images[tech].img} alt={Images[tech].toolTip} />
    </div>
  );
};
