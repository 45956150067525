import { useEffect, useState, useCallback, useContext } from "react";
import "../../../assets/css/Adamr.sass";
import { AdamrStoryContainer } from "./Story/Story";
import { AdamrMenuContainer } from "./Menu/Menu";
import { animations } from "../../../assets/js/animations";
import { LoaderContainer } from "../../Loader";
import { Context } from "../../../store";

export const AdamrContainer = () => {
  const [story, setStory] = useState(null);
  const [click, setClick] = useState(null);
  const [active, setActive] = useState(null);

  //Animations
  const animationRef = useCallback((node) => {
    if (node !== null) node.animate(...animations[node.id]);
  }, []);

  //DATA CACHING & PRE-LOADING

  const [state, dispatch] = useContext(Context);
  const allAdamrs = state.allAdamrs;

  const onFirstRender = () => {
    if (allAdamrs.length === 0) {
      dispatch("GET_ADAMRS");
    }
  };

  useEffect(onFirstRender, [dispatch, allAdamrs]);

  // Displaying project
  const onMenuClick = () => {
    let current = 0;
    if (click) current = 0 || parseInt(click.target.dataset.id);
    if (allAdamrs) setStory(allAdamrs[current]);
    setActive(current);
  };
  useEffect(onMenuClick, [click, allAdamrs]);

  //Container rendering
  return (
    <div id="space-adamr" ref={animationRef}>
      <div id="adamr-container">
        <div id="adamr-backplate" ref={animationRef}>
          {story ? (
            <div id="adamr-content-grid">
              <AdamrMenuContainer stories={allAdamrs} setRequest={setClick} active={active} />
              <AdamrStoryContainer active={active} story={story} />
            </div>
          ) : (
            <LoaderContainer />
          )}
        </div>
      </div>
    </div>
  );
};
