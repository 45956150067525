import "../assets/css/Footer.sass";
import GitHubLogo from "../assets/img/github.png";
import LinkedInLogo from "../assets/img/linkedIn.png";
import FooterLogo from "../assets/img/default-monochrome-black.svg";
import CodewarsLogo from "../assets/img/codewars-logo.svg";
import CodeacademyLogo from "../assets/img/codecademy-logo.svg";
import { config } from "../assets/js/config";

export const Footer = () => {
  if (config.debug) console.log("Element Footer re-rendered :( ");
  //Presentation rendering
  return (
    <footer id="space-footer">
      <img id="footer-adamr-logo" src={FooterLogo} alt="" /> <p id="footer-copyright-text">&copy; 2021</p>
      <img
        id="footer-github-logo"
        className="logo-clickable"
        onClick={() => window.open("https://github.com/adamr-space")}
        src={GitHubLogo}
        alt=""
      />
      <img
        id="footer-codeacademy-logo"
        className="logo-clickable"
        onClick={() => window.open("https://www.codecademy.com/profiles/adamr.space")}
        src={CodeacademyLogo}
        alt=""
      />
      <div
        id="codewars-bundle"
        className="logo-clickable"
        onClick={() => window.open("https://www.codewars.com/users/adam%20R")}
      >
        <div id="codewars-logo">
          <img id="footer-codewars-logo" src={CodewarsLogo} alt="" />
          <p id="footer-codewars-text">Codewars</p>
        </div>

        <img src="https://www.codewars.com/users/adam%20R/badges/large" alt="" />
      </div>
      <img
        id="footer-linkedin-logo"
        className="logo-clickable"
        onClick={() => window.open("https://www.linkedin.com/in/adamr-space/")}
        src={LinkedInLogo}
        alt=""
      />
    </footer>
  );
};
