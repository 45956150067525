import "../../../assets/css/Projects.sass";
import { useEffect, useState, useCallback, useContext } from "react";
import { Project } from "../../../components/Space/Projects/Project/Project";
import { Controls } from "../../../components/Space/Projects/Controls/Controls";
import { animations } from "../../../assets/js/animations";
import { LoaderContainer } from "../../Loader";
import { Context } from "../../../store";

export const ProjectsContainer = () => {
  const [project, setProject] = useState(null);
  const [shift, setShift] = useState({ target: { dataset: { id: 0, isLoading: true } } });

  //Animations
  const animationRef = useCallback((node) => {
    if (node !== null) node.animate(...animations[node.id]);
  }, []);

  //DATA CACHING & PRE-LOADING

  const [state, dispatch] = useContext(Context);
  const allProjects = state.allProjects;

  const onFirstRender = () => {
    if (allProjects.length === 0) {
      dispatch("GET_PROJECTS");
    }
  };

  useEffect(onFirstRender, [dispatch, allProjects]);

  //Shifting projects EFFECT
  const onProjectShift = () => {
    if (allProjects && allProjects.length > 0) {
      const projectsCount = allProjects.length;
      const direction = !shift.target.dataset.isLoading ? parseInt(shift.target.dataset.direction) : 0;
      let targetProject = 0 || parseInt(shift.target.dataset.id);
      if (!shift.target.dataset.isLoading) {
        direction === 0 ? targetProject-- : targetProject++;
        targetProject = targetProject < 0 ? projectsCount - 1 : targetProject;
        targetProject = targetProject > projectsCount - 1 ? 0 : targetProject;
        setProject(allProjects[targetProject]);
      } else setProject(allProjects[0]);
    }
  };
  useEffect(onProjectShift, [shift, allProjects, dispatch]);

  //Container rendering
  return allProjects && allProjects.length > 0 ? (
    <div id="space-projects" ref={animationRef}>
      {project ? (
        <div id="projects-container">
          <Controls id={project.id} shiftProject={setShift} dir={0} />
          <Project project={project} shiftProject={setShift}/>
          <Controls id={project.id} shiftProject={setShift} dir={1} />
        </div>
      ) : (
        ""
      )}
    </div>
  ) : (
    <LoaderContainer />
  );
};
