import Modal from "react-modal";
export const AdamrModal = ({ setModal, modal, animationRef }) => {
  //Modal
  Modal.setAppElement("#root");
  //Presentational rendering
  return (
    <Modal
      className={"modal-body"}
      overlayClassName={"modal-overlay"}
      isOpen={modal.modalOpened}
      onRequestClose={() => setModal({ modalOpened: false })}
      contentLabel="Modal with image"
    >
      <h2>Hello stranger</h2>

      <h2>Send e-mail to adam@adamr.space:</h2>

      <form action="mailto:someone@example.com" method="post" encType="text/plain" id="form-email" ref={animationRef}>
        <div className="contact-details">
          Name:
          <input type="text" name="name" />
          E-mail:
          <input type="text" name="mail" />
        </div>
        Email Text:
        <br />
        <div className="email-content">
          <textarea type="text" name="text" value="This functionality is not yet implemented! Please contact via other channels."/>
        </div>
        <br />
        <div className="modal-btns">
          <input type="submit" value="Send" />
          <input type="reset" value="Reset" />
          <button type="button" onClick={() => setModal({ modalOpened: false })}>
            Close
          </button>
        </div>
      </form>
    </Modal>
  );
};
