import { Story } from "../../../../components/Space/Adamr/Story/Story";
import { animations } from "../../../../assets/js/animations";
import { AdamrModalContainer } from "./Modal";
import { useCallback, useState } from "react";
export const AdamrStoryContainer = ({ story, active }) => {
  //Animations
  const animationRef = useCallback((node) => {
    if (node !== null) node.animate(...animations[node.id]);
  }, []);

  //Modal
  const [modal, setModal] = useState({ modalOpened: false });

  //Container rendering
  return (
    <>
      {modal.modalOpened ? (
        <AdamrModalContainer setModal={setModal} modal={modal} />
      ) : (
        <Story story={story} active={active} animationRef={animationRef} setModal={setModal} />
      )}
    </>
  );
};
