import "../../../assets/css/Home.sass";
import { Images } from "../../../assets/img/Images";
import { config } from "../../../assets/js/config";

export const Home = ({ homeRef, reactRef }) => {
  if (config.debug) console.log("Element Home re-rendered :( ");
  return (
    <div id="space-home" className="banner" ref={homeRef}>
      <p>Welcome to</p>
      <div id="default-banner">
        <img src={Images.DefaultLogo.img} alt=""/>
      </div>
      <div id="react-banner" ref={reactRef}>
        <img id="react-logo" src={Images.ReactLogo.img} alt="" />
        <p id="react-text">Made with REACT</p>
      </div>
    </div>
  );
};
